import React from 'react';
import { Row, Col } from 'antd/es/grid';
import brandHarrods from 'static/images/brandHarrods.png';
import brandDior from 'static/images/brandDior.png';
import brandLvmh from 'static/images/brandLvmh.png';
import brandArmani from 'static/images/brandArmani.png';
import blandLOfficiel from 'static/images/blandLOfficiel.png';
import brandCreditSuisse from 'static/images/brandCreditSuisse.png';

export const BrandsLogos = () => {
  return (
    <Row type="flex" justify="space-around" align="bottom" className="mt-30 mb-10" gutter={[50, 50]}>
      <Col xs={8} sm={4}>
        <img src={brandHarrods} alt="Harrods" className="max-w_100" />
      </Col>
      <Col xs={8} sm={3}>
        <img src={brandDior} alt="Dior" className="max-w_100" />
      </Col>
      <Col xs={8} sm={3}>
        <img src={brandLvmh} alt="Lvmh" className="max-w_100" />
      </Col>
      <Col xs={8} sm={3}>
        <img src={brandArmani} alt="Armani" className="max-w_100" />
      </Col>
      <Col xs={8} sm={3}>
        <img src={blandLOfficiel} alt={"L'Officiel"} className="max-w_100" />
      </Col>
      <Col xs={8} sm={4}>
        <img src={brandCreditSuisse} alt="Credit Suisse" className="max-w_100" />
      </Col>
    </Row>
  );
};
