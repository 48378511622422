import { Button, Container } from '@axmit/clp-library';
import Typography from 'antd/es/typography';
import Icon from 'antd/es/icon';
import { Col, Row } from 'antd/es/grid';
import React from 'react';
import 'static/css/index.scss';
import Img from 'gatsby-image';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { LandingCarousel } from 'components';
import { BrandsLogos } from 'components/BrandsLogos';
import { useTranslation } from 'react-i18next';
import SEO from 'components/Seo';
import { AppLandingHelper } from '@axmit/router-app-landing/index';
import { EClientRoutes, ELandingRoutes } from 'const';
import down from 'static/images/down.png';
import { getTranslates } from 'helpers/translate.helper';

export default () => {
  const {
    i18n: { language }
  } = useTranslation();

  const data = useStaticQuery(query);
  const imagesBalloon = [
    data.balloonMobile.childImageSharp.fluid,
    {
      ...data.balloonDesktop.childImageSharp.fluid,
      media: `(min-width: 768px)`
    }
  ];

  return (
    <div className="main-wrapper">
      <SEO />

      <Container>
        <div className="land-block-first land-block-first_with-arrow">
          <Row type="flex" align="middle">
            <Col xs={13} sm={10}>
              <Typography.Title className="clp-typography-title clp-typography-title_size_md mb-25">
                {getTranslates(data.allStrapiLesLandIndex, 'header', language)}
              </Typography.Title>

              <Typography.Text className="clp-typography-text clp-typography-text_size_md mb-20 clp-typography-pale">
                {getTranslates(data.allStrapiLesLandIndex, 'content1', language)}
              </Typography.Text>

              <Typography.Text className="clp-typography-text clp-typography-text_size_md mb-50 clp-typography-pale">
                {getTranslates(data.allStrapiLesLandIndex, 'content2__link1', language)}
                <Link to={ELandingRoutes.SignInfoCorporate} className="landing-link">
                  {getTranslates(data.allStrapiLesLandIndex, 'content2__link2', language)}
                </Link>{' '}
                {getTranslates(data.allStrapiLesLandIndex, 'content2__link3', language)}
                <Link to={ELandingRoutes.SignInfo} className="landing-link">
                  {getTranslates(data.allStrapiLesLandIndex, 'content2__link4', language)}
                </Link>{' '}
                {getTranslates(data.allStrapiLesLandIndex, 'content2__link5', language)}
              </Typography.Text>

              <Button
                type="primary"
                className="pl-20 pr-20 hidden_mob"
                onClick={() => AppLandingHelper.toggleRoute(EClientRoutes.CreateRequest)}
              >
                <span>
                  {getTranslates(data.allStrapiLesLandIndex, 'btn__submit', language)}
                  <Icon type="right" className="ml-20" />
                </span>
              </Button>
            </Col>

            <Col xs={11} sm={{ span: 12, offset: 2 }}>
              <Img
                className="land-img-balloon"
                fluid={imagesBalloon}
                alt="Smiling happily"
                imgStyle={{ objectFit: 'contain', objectPosition: 'right' }}
              />
            </Col>
          </Row>

          <Button type="link" onClick={scrollToReview} className="down-navigation-btn">
            <img alt="down" src={down} />
          </Button>
        </div>

        <Button
          type="primary"
          className="ant-btn_mob-block mb-50 hidden_desk"
          size="small"
          onClick={() => AppLandingHelper.toggleRoute(EClientRoutes.CreateRequest)}
        >
          <span>
            {getTranslates(data.allStrapiLesLandIndex, 'btn__submit', language)}
            <Icon type="right" className="ml-20" />
          </span>
        </Button>

        <Row type="flex" align="middle" className="hidden_mob land-block-review" id="review-section">
          <Col sm={{ span: 24, order: 2 }} xl={{ span: 12, order: 1 }}>
            <div className="revert-carousel clp-carousel-pale">
              <LandingCarousel
                className="hidden_mob"
                items={[
                  {
                    sign: getTranslates(data.allStrapiLesLandIndex, 'review1__sign', language),
                    content: getTranslates(data.allStrapiLesLandIndex, 'review1__content', language)
                  },
                  {
                    sign: getTranslates(data.allStrapiLesLandIndex, 'review2__sign', language),
                    content: getTranslates(data.allStrapiLesLandIndex, 'review2__content', language)
                  },
                  {
                    sign: getTranslates(data.allStrapiLesLandIndex, 'review3__sign', language),
                    content: getTranslates(data.allStrapiLesLandIndex, 'review3__content', language)
                  },
                  {
                    sign: getTranslates(data.allStrapiLesLandIndex, 'review4__sign', language),
                    content: getTranslates(data.allStrapiLesLandIndex, 'review4__content', language)
                  }
                ]}
              />
            </div>
          </Col>
          <Col sm={{ span: 24, order: 1 }} xl={{ span: 12, order: 1 }}>
            <div className="land-img__wrapper-half">
              <Img fluid={data.carouselPreview.childImageSharp.fluid} alt="Smiling happily" />
            </div>
          </Col>
        </Row>

        <Typography.Title className="clp-typography-title clp-typography-title_size_md mb-25 mt-100 mob-text_center" level={3}>
          {getTranslates(data.allStrapiLesLandIndex, 'brands__header', language)}
        </Typography.Title>

        <BrandsLogos />
      </Container>
    </div>
  );
};

const scrollToReview = () => {
  document?.getElementById('review-section')?.scrollIntoView({ behavior: 'smooth', block: 'start' });
};

export const query = graphql`
  query {
    carouselPreview: file(relativePath: { eq: "images/carouselPeople.jpg" }) {
      childImageSharp {
        fluid(quality: 90, sizes: "(min-width: 720px) 50vw, 720px") {
          ...GatsbyImageSharpFluid
        }
      }
    }
    balloonMobile: file(relativePath: { eq: "images/hotAirBalloonMob.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 170, traceSVG: { color: "#0f0d18" }) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    balloonDesktop: file(relativePath: { eq: "images/hotAirBalloonDesk.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 356, traceSVG: { color: "#0f0d18" }) {
          ...GatsbyImageSharpFluid_tracedSVG
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    allStrapiLesLandIndex {
      edges {
        node {
          header__en
          header__fr
          content1__en
          content1__fr
          content2__link1__en
          content2__link1__fr
          content2__link2__en
          content2__link2__fr
          content2__link3__en
          content2__link3__fr
          content2__link4__en
          content2__link4__fr
          content2__link5__en
          content2__link5__fr
          btn__submit__en
          btn__submit__fr
          review1__content__en
          review1__content__fr
          review1__sign__en
          review1__sign__fr
          review2__content__en
          review2__content__fr
          review2__sign__en
          review2__sign__fr
          review3__content__en
          review3__content__fr
          review3__sign__en
          review3__sign__fr
          review4__content__en
          review4__content__fr
          review4__sign__en
          review4__sign__fr
          brands__header__en
          brands__header__fr
        }
      }
    }
  }
`;
